<template>
    <div v-if="dialogVisible">
        <el-dialog
            title="座位图"
            :visible.sync="dialogVisible"
            width="90%"
            :before-close="resetform"
        >
            <div class="btnbox">
                <el-button type="primary" size="mini" class="font14" @click="submit"
                >保存修改
                </el-button
                >
                <el-button
                    type="primary"
                    size="mini"
                    class="font14"
                    @click="dialogVisible = false"
                >关闭
                </el-button
                >
            </div>
            <div class="btnbox" style="border-bottom: none">
                <el-button
                    :type="showzxt ? 'warning' : 'success'"
                    size="mini"
                    class="font14"
                    @click="showzxt = !showzxt"
                >{{ showzxt ? "关闭主席台" : "开启主席台" }}
                </el-button
                >
                <!--        <el-button-->
                <!--          v-show="showzxt"-->
                <!--          type="success"-->
                <!--          size="mini"-->
                <!--          class="font14"-->
                <!--          @click="addzxt"-->
                <!--          >新增主席台位置</el-button-->
                <!--        >-->
                <!--        <el-button-->
                <!--          v-show="showzxt"-->
                <!--          type="warning"-->
                <!--          size="mini"-->
                <!--          class="font14"-->
                <!--          @click="delzxt"-->
                <!--          >删除主席台位置</el-button-->
                <!--        >-->
                <!-- <el-button size="mini" class="font14" @click="setguodao"
                  >设置过道</el-button
                > -->
                <el-button
                    v-show="guodao.length >= 1"
                    type="info"
                    size="mini"
                    class="font14"
                    @click="delgd"
                >删除过道
                </el-button
                >
                <el-button type="success" size="mini" class="font14" @click="addlie"
                >新增列
                </el-button
                >
                <el-button type="warning" size="mini" class="font14" @click="dellie"
                >删除列
                </el-button
                >
                <el-button type="success" size="mini" class="font14" @click="addhang"
                >新增行
                </el-button
                >
                <el-button type="warning" size="mini" class="font14" @click="delhang"
                >删除行
                </el-button
                >
            </div>
            <!-- style="width: 100%; overflow: auto" -->
            <div class="addbox" style="width: 100%; overflow: auto">
                <div class="left_info" style="margin: auto;">
                    <!-- width: 1350px; overflow: auto;  -->
<!--                              <div-->
<!--                                  v-show="showzxt"-->
<!--                                  style="display: flex; align-items: center;text-align: center"-->
<!--                              >-->
<!--                                <div-->
<!--                                    v-for="(item, index) in zxset"-->
<!--                                    :key="index"-->
<!--                                    style="display: flex; flex-direction: column"-->
<!--                                >-->
<!--                                  <div-->
<!--                                      class="inset zxzw"-->
<!--                                      :id="'zx' + index"-->
<!--                                      @click="item.status = item.status == 1 ? 2 : 1;"-->
<!--                                      :style="{ backgroundColor: item.status == 2 ? '#c0c0c0' : '' }"-->
<!--                                  >-->
<!--                                    {{ item.status == 1 ? "可用" : "不可用" }}-->
<!--                                  </div>-->
<!--                                  <div style="margin-right: 10px;">-->
<!--                                    {{ setnumber(index, zxset.length) }}-->
<!--                                  </div>-->
<!--                                </div>-->
<!--                              </div>-->
                    <!--          和渲染座位一样渲染主席台，数据结构相同-->
                    <div style="max-width: 100%;">
                        <div>
                            <div
                                style="
                                margin-top: 20px;
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                "
                            >
                                <div
                                    v-for="(item, index) in zxset"
                                    :key="index"
                                    style="display: flex; align-items: center; margin-bottom: 10px;width: auto;"
                                >
                                    <div
                                        class="innerbox"
                                        v-for="(item2, index2) in item.lie"
                                        :key="index2"
                                        style="
                                        display: flex;
                                        align-items: center;
                                        flex-direction: column;
                                        text-align: center;
                                      "
                                    >
                                        <div
                                            class="inset"
                                            :id="index + ',' + index2"
                                            :style="setstyle(index2, item2, 'text')"
                                            @click="item2.status = item2.status == 1 ? 2 : 1"
                                        >
                                            {{ item2.status == 1 ? "可用" : "不可用" }}
                                            <!-- 可用 -->
                                        </div>
                                        <div
                                            style="margin-right: 10px;display: flex;flex-direction: column;"
                                            :style="setstyle(index2, item2, 'number')"
                                            @click="editZxSet(index, index2)"
                                        >
                                            {{ index + 1 + "-" + (index2 + 1) }}
                                            <div style="font-size: 10px;width: 65px;">
                                                <el-input
                                                    v-model="item2.spai"
                                                    size="mini"
                                                    placeholder="排"
                                                    style="width: 50px"
                                                    @blur="editZxPaiSet(list,index,item2)"
                                                ></el-input>
                                                排
                                            </div>
                                            <div style="font-size: 10px;width: 65px;">
                                                <el-input
                                                    v-model="item2.slie"
                                                    size="mini"
                                                    placeholder="列"
                                                    style="width: 50px"
                                                    @blur="editSet(list,index2,item2)"
                                                ></el-input>
                                                列
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- v-show="showzxt"  -->
                    <div class="zxt_im">主席台</div>


                    <div style="max-width: 100%;">
                        <div>
                            <div
                                style="
                                margin-top: 20px;
                                position: relative;
                                display: flex;
                                flex-direction: column;
                                "
                            >
                                <div
                                    v-for="(item, index) in list"
                                    :key="index"
                                    style="display: flex; align-items: center; margin-bottom: 10px;width: auto;"
                                >
                                    <div
                                        class="innerbox"
                                        v-for="(item2, index2) in item.lie"
                                        :key="index2"
                                        style="
                    display: flex;
                    align-items: center;
                    flex-direction: column;
                    text-align: center;
                  "
                                    >
                                        <div
                                            class="inset"
                                            :id="index + ',' + index2"
                                            :style="setstyle(index2, item2, 'text')"
                                            @click="item2.status = item2.status == 1 ? 2 : 1"
                                        >
                                            {{ item2.status == 1 ? "可用" : "不可用" }}
                                            <!-- 可用 -->
                                        </div>
                                        <div
                                            style="margin-right: 10px;display: flex;
                          flex-direction: column;"
                                            :style="setstyle(index2, item2, 'number')"
                                            @click="editSet(index, index2)"
                                        >
                                            {{ index + 1 + "-" + (index2 + 1) }}
                                            <div style="font-size: 10px;width: 65px;">
                                                <el-input
                                                    v-model="item2.spai"
                                                    size="mini"
                                                    placeholder="排"
                                                    style="width: 50px"
                                                    @blur="editPaiSet(list,index,item2)"
                                                ></el-input>
                                                排
                                            </div>
                                            <div style="font-size: 10px;width: 65px;">
                                                <el-input
                                                    v-model="item2.slie"
                                                    size="mini"
                                                    placeholder="列"
                                                    style="width: 50px"
                                                    @blur="editSet(list,index2,item2)"
                                                ></el-input>
                                                列
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            room_id: "",

            dialogVisible: false,
            metting_id: "",
            list: [],
            zhuList: [], //主席台位置
            draggingIndex: 0,
            userlist: [],
            yuanindex: "", //座位互拖-原始index

            zxset: [], //主席台位置
            showzxt: true,
            guodao: [], //设置过道的数据
        };
    },
    computed: {
        midIndex() {
            return Math.floor(this.list[0].lie.length / 2);
        },
    },
    created() {
        // this.init()
    },
    methods: {
        resetform() {
            this.dialogVisible = false
        },
        submit() {
            console.log('this.list', this.list);
            console.log('this.zxset', this.zxset);
            //解构list,将主席台位置放到最前面


            this.$post({
                // url: "/api/roomzc/roomedit",
                url: "/api/login/roomedit", // '/api/roomzc/roomedit',
                params: {
                    room_id: this.room_id,
                    pai: JSON.stringify(this.list), //this.list,
                    zhu: JSON.stringify(this.zxset),
                },
            }).then((res) => {
                this.$message.success("保存成功");
                this.dialogVisible = false;
            });
        },
        addhang() {
            //新增行
            let group = [];
            for (var k = 1; k <= this.list[0].lie.length; k++) {
                group.push({
                    user_id: "",
                    name: "",
                    status: 1,
                    slie: 0,
                    spai: 0,
                });
            }
            this.$set(this.list, [this.list.length], {lie: group});
        },
        delhang() {
            //删除行
            this.list.splice(this.list.length - 1, 1);
        },
        addlie() {
            //新增列

            this.list.forEach((item, index) => {
                this.$set(this.list[index].lie, [item.lie.length], {
                    user_id: "",
                    name: "",
                    status: 1,
                    slie: 0,
                    spai: 0,
                });
            });

            console.log(this.list);
        },
        dellie() {
            //删除列
            this.list.forEach((item, index) => {
                this.list[index].lie.splice(item.length - 1, 1);
            });

            console.log(this.list);
        },
        setstyle(index2, item2, type) {
            // console.log(type);
            let obj = {};
            if (type != "number") {
                obj.backgroundColor = item2.status == 2 ? "#c0c0c0" : "";
            }
            return obj;
        },
        setguodao() {
            this.$prompt("请输入在第几列后面添加过道", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /^\d+$/,
                inputErrorMessage: "请输入数字",
            })
                .then(({value}) => {
                    if (value <= 0 || value > this.list[0].length) {
                        this.$message.warning("请输入正确的数字");
                        return;
                    }
                    this.guodao.push(Number(value));
                })
                .catch(() => {
                });
        },
        delgd() {
            this.$prompt("请输入需删除第几列后面的过道", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /^\d+$/,
                inputErrorMessage: "请输入数字",
            })
                .then(({value}) => {
                    const element = Number(value); // 需要删除的元素值
                    const index = this.guodao.indexOf(element); // 获取需要删除的元素在数组中的索引
                    if (index !== -1) {
                        // 如果需要删除的元素存在于数组中
                        this.guodao.splice(index, 1); // 删除该元素
                    } else {
                        this.$message.warning("请输入正确的数字");
                    }
                })
                .catch(() => {
                });
        },
        addzxt() {
            let obj = {
                user_id: "",
                name: "",
            };
            this.zxset.push(obj);
        },
        delzxt() {
            if (this.zxset.length == 1) {
                this.$message.warning("不能删除最后一个");
                return;
            }
            this.zxset.splice(this.zxset.length - 1, 1);
        },
        init() {
            //无数据时，初始化
            // for (var j = 1; j <= 7; j++) {
            // 	var group = [];
            // 	for (var k = 1; k <= 20; k++) {
            // 		group.push({
            // 			user_id: '',
            // 			name: '',
            // 			status:1
            // 		});
            // 	}
            // 	this.list.push(group);
            // }
            this.zxset = [
                {
                    id: "",
                    lie: "0",
                    pai: "1",
                    room_id: "",
                    sfzhu: "1",
                    slie: "0",
                    status: "1",
                    wymeeting_id: "",
                    xu: "0",
                },
                {
                    id: "",
                    lie: "0",
                    pai: "1",
                    room_id: "",
                    sfzhu: "1",
                    slie: "0",
                    status: "1",
                    wymeeting_id: "",
                    xu: "0",
                },
                {
                    id: "",
                    lie: "0",
                    pai: "1",
                    room_id: "",
                    sfzhu: "1",
                    slie: "0",
                    status: "1",
                    wymeeting_id: "",
                    xu: "0",
                },
                {
                    id: "",
                    lie: "0",
                    pai: "1",
                    room_id: "",
                    sfzhu: "1",
                    slie: "0",
                    status: "1",
                    wymeeting_id: "",
                    xu: "0",
                },
                {
                    id: "",
                    lie: "0",
                    pai: "1",
                    room_id: "",
                    sfzhu: "1",
                    slie: "0",
                    status: "1",
                    wymeeting_id: "",
                    xu: "0",
                },
                {
                    id: "",
                    lie: "0",
                    pai: "1",
                    room_id: "",
                    sfzhu: "1",
                    slie: "0",
                    status: "1",
                    wymeeting_id: "",
                    xu: "0",
                },
                {
                    id: "",
                    lie: "0",
                    pai: "1",
                    room_id: "",
                    sfzhu: "1",
                    slie: "0",
                    status: "1",
                    wymeeting_id: "",
                    xu: "0",
                },
                // {
                //   user_id: "",
                //   name: "",
                //   status: 1,
                // },
            ];
            // console.log(this.list);

            this.$post({
                url: "/api/roomzc/index",
                params: {
                    room_id: this.room_id,
                },
            }).then((res) => {
                this.list = res.list.pai;
                this.zxset = res.list.zhu;
                // this.zxset = this.list.slice(this.list.length / 2);

                console.log('座位', this.list);
                console.log('主席台', this.zxset);
            });
        },

        setnumber(index, length) {
            //显示座位下的序号
            let jis = [];
            let ous = [];
            for (let i = 1; i <= length; i++) {
                if (i % 2 == 0) {
                    ous.push(i);
                } else {
                    jis.push(i);
                }
            }
            jis.sort((a, b) => b - a);
            let list = jis.concat(ous);
            return list[index];
        },
        /**
         * @param {Array} list - 全部座位。
         * @param {number} index2 - 当前编辑的列的索引。
         * @param {Object} item2 - 当前编辑的这个座位。
         */
        editSet(list, index2, item2) {
            //批量修改 每排 对应列 的slie
            // list.forEach((item, index) => {
            //   this.$set(this.list[index].lie[index2], "slie", item2.slie);
            //   console.log(index,index2)
            // });
            //单独修改 每排 对应列 的slie
            this.$set(this.list[index2].lie, "slie", item2.slie);
        },
        editPai(list, index, item) {
            //修改 每排 的pai
            this.$set(this.list[index], "spai", item.spai);
        },
        editPaiSet(list, index, item2) {
            //批量修改 list下的pai下的lie中的 每个元素 的spai
            // list.forEach((item, index2) => {
            //     this.$set(this.list[index].lie[index2], "spai", item2.spai);
            //     console.log(index, index2)
            // });
            //单独修改
            this.$set(this.list[index], "spai", item2.spai);
        },

        editZxSet(list, index2, item2) {
            this.$set(this.zxset[index2].lie, "slie", item2.slie);
        },
        editZxPai(list, index, item) {
            this.$set(this.zxset[index], "spai", item.spai);
        },
    },
};
</script>

<style lang="scss" scoped>
.inset {
    width: 50px;
    height: 80px;
    border: 1px solid #333;
    padding: 5px;
    //   border-right: none;
    writing-mode: vertical-lr;
    display: flex;
    align-items: center;
    justify-content: center;
    text-orientation: upright;
    cursor: pointer;
    position: relative;
    line-height: 20px;

    border-radius: 5px;
    margin-right: 10px;

    .deleteicon {
        position: absolute;
        right: 0;
        top: 0;
        color: #d00;
        display: none;
    }
}

.zxzw {
    margin: 5px;
    border-right: 1px solid #333;
}

.inset:hover {
    .deleteicon {
        display: block;
    }
}

.innerbox:nth-last-child(1) {
    .inset {
        //     border-right: 1px solid #333;
    }
}

.zuowei_pai {
    display: flex;
    margin-bottom: 10px;
    width: 100%;
}

.zuowei_lie {
    padding: 5px 10px;
    margin-right: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.zuowei_lie:nth-last-child(1) {
    margin-right: 0;
}

.zxt {
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    padding-left: 40px;
}

.zxt_im {
    background-color: rgb(180, 180, 180);
    width: 100%;
    padding: 3px 0;
    text-align: center;
    font-size: 16px;
}

.zxt_item {
    padding: 5px 10px;
    margin-right: 15px;
}

.zxt_item:nth-last-child(1) {
    margin-right: 0;
}

.left_info {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.item_line {
    display: flex;
    align-items: center;
    margin-bottom: 30px;

    .tlename {
        width: 120px;
        font-size: 14px;

        text-align: right;
        color: #333;
    }

    .bticon {
        width: 10px;
        height: 20px;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        line-height: 34px;
        font-size: 26px;
        color: red;
    }
}

.infobox {
    width: 100%;
    padding-left: 50px;
    padding-right: 30px;

    .contbox {
        display: flex;
        padding: 20px 40px;
        height: 750px;
        transition: all 0.5s;
        overflow: hidden;
    }

    .colosecont {
        height: 0;
        padding: 0;
    }

    .title {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 14px 20px;
        color: #ec4a4a;
        border-bottom: 1px solid #ec4a4a;
    }
}

.addbox {
    height: 80vh;
    width: 100%;
    padding: 30px;
    background-color: #fff;
}

.add_cont {
    width: 100%;
    background-color: #fff;
    padding: 10px;
}

.btnbox {
    display: flex;
    align-items: center;
    padding: 10px 0;
    border-bottom: 1px solid #eee;
}

::v-deep .el-form-item__error {
    padding-top: 0;
}

::v-deep .el-form-item {
    margin-bottom: 10px;
}

::v-deep .el-form-item__label {
    color: #999;
    font-size: 14px;
}

::v-deep
.el-form-item.is-required:not(.is-no-asterisk)
> .el-form-item__label:before {
    content: "" !important;
}

::v-deep .el-input--small .el-input__icon {
    color: #333;
    font-weight: bold;
}

::v-deep .el-dialog {
    margin: 0 !important;
}

::v-deep .el-dialog__header {
    border-bottom: 1px solid #eee;
}

::v-deep .el-dialog__footer {
    border-top: 1px solid #eee;
}

::v-deep .el-dialog__body {
    padding: 10px;
    padding-top: 0px;
}

::v-deep .el-tabs--border-card {
    height: 100%;
}

::v-deep .el-tabs--border-card {
    box-shadow: 0 0 0 #fff;
}

::v-deep .el-tabs__header {
    background-color: #fff;
}
</style>
